import React, { FC, useState } from 'react'
import { HeadingSM, ParagraphLG } from '@/atoms/Text'
import { slugs } from '@/constants/slugs'
import { GBFadeInExperiment, ExperimentVariation } from '@/experimentation'
import { useGuildUser } from '@/services/GuildUserService'
import { useTranslate } from '@/utils/translate/translate-client'
import { ProjectJourneyModal } from './ProjectJourneyModal'
import { ProjectJourneySuperPhaseCard } from './ProjectJourneySuperPhaseCard'
import { ProjectJourneySuperPhase } from './types'
import { ProjectJourneyData, useProjectJourneyData } from './useProjectJourneyData'
import { getSuperPhaseIcon } from './utils'

interface Props {
  projectSlug?: string
  projectData?: ProjectJourneyData
  heading: string
  subHeading: string
  className?: string
  gridClassName?: string
}

const HIDE_PROJECT_SLUGS = [
  slugs.aWeekAwaySeries,
  slugs.braveTheDark,
  slugs.forKingAndCountryADrummerBoyChristmasLive,
  slugs.insideAngelStudios,
  slugs.kingOfKings,
  slugs.lostOnAMountainInMaine,
  slugs.quitters,
  slugs.redemptionOfHenryMyers,
  slugs.riotAndTheDanceSeries,
  slugs.sevenDaysInUtopia,
  slugs.standingUp,
  slugs.theBlind,
  slugs.toEndAllWars,
  slugs.twentyThreeBlast,
]

const ProjectJourney: FC<Props> = ({
  heading,
  subHeading,
  projectData,
  projectSlug,
  className,
  gridClassName = 'grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-4',
}) => {
  const { t } = useTranslate('watch')
  const [selectedSuperPhase, setSelectedSuperPhase] = useState<ProjectJourneySuperPhase | null>(null)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const { isGuildMember } = useGuildUser()

  if (!projectData) return null

  if (HIDE_PROJECT_SLUGS.includes(projectSlug!)) return null

  return (
    <>
      <div className={className}>
        <HeadingSM weight="bold" className="mb-6">
          {heading}
        </HeadingSM>
        <ParagraphLG className="mb-6">{subHeading}</ParagraphLG>

        <div className={gridClassName}>
          {projectData.superPhases.map((superPhase) => {
            const { id, title, phases } = superPhase
            const completed = phases.filter((ph) => ph.status === 'completed')
            const current = phases.filter((ph) => ph.status === 'current')
            const label =
              completed.length > 0
                ? t('nPhasesCompleted', '{{completed}} of {{total}} completed', {
                    completed: completed.length,
                    total: phases.length,
                  })
                : current.length > 0
                ? t('inProgress', 'In Progress')
                : t('pending', 'pending')
            const progress = completed.length > 0 ? completed.length / phases.length : current.length > 0 ? 0.1 : 0

            return (
              <ProjectJourneySuperPhaseCard
                key={id}
                title={title}
                label={label}
                icon={getSuperPhaseIcon(id)}
                progress={progress}
                onClick={() => {
                  setSelectedSuperPhase(superPhase)
                  setModalIsOpen(true)
                }}
              />
            )
          })}
        </div>
      </div>

      <ProjectJourneyModal
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        {...selectedSuperPhase}
        userIsGuildMember={isGuildMember}
      />
    </>
  )
}

export const GatedProjectJourney: React.FC<Omit<Props, 'heading' | 'subHeading'>> = (props) => {
  const { t } = useTranslate('watch')
  const projectData = useProjectJourneyData(props.projectSlug)

  if (projectData && projectData.shouldDisplay) {
    return (
      <GBFadeInExperiment experimentName="project-journey" defaultValue="off">
        <ExperimentVariation variation="guild-focused">
          <ProjectJourney
            {...props}
            projectData={projectData}
            heading={t('guildPowered', 'Guild Powered')}
            subHeading={t('seeHowAngelGuildBringsToLife', 'See how the Angel Guild is bringing this story to life.')}
          />
        </ExperimentVariation>

        <ExperimentVariation variation="journey-focused">
          <ProjectJourney
            {...props}
            projectData={projectData}
            heading={t('projectJourney', 'Project Journey')}
            subHeading={t(
              'followEachPhaseOfTheJourney',
              'Follow each phase of the journey to see this story come to life.',
            )}
          />
        </ExperimentVariation>
      </GBFadeInExperiment>
    )
  }

  return null
}
