import { ApolloQueryResult } from '@apollo/client'
import { GuildUpdate, ProjectV2 } from '@/organisms/ProjectJourney/types'
import { FetchProjectV2ForProjectJourneyQuery, FetchProjectForProjectJourneyQuery } from '@/types/codegen-federation'
import { isDefined } from '@/utils/types'
import { getWebClient } from '../ApolloClient'
import { GET_PROJECT_V2, GET_PROJECT } from './queries'

export const getProjectJourneyData = async (
  projectSlug: string,
): Promise<{
  guildUpdates: GuildUpdate[]
  projectV2: ProjectV2 | undefined
}> => {
  const client = getWebClient()
  const { data: projectV2Data } = await client.query<FetchProjectV2ForProjectJourneyQuery>({
    query: GET_PROJECT_V2,
    variables: { projectSlug },
    errorPolicy: 'all',
  })

  const projectUuid = projectV2Data.projectV2BySlug?.id
  let projectResult: ApolloQueryResult<FetchProjectForProjectJourneyQuery> | undefined = undefined
  if (projectUuid) {
    projectResult = await client.query<FetchProjectForProjectJourneyQuery>({
      query: GET_PROJECT,
      variables: { projectUuid },
      errorPolicy: 'all',
    })
  }

  return {
    guildUpdates: projectResult?.data.guildUpdates?.filter(isDefined) ?? [],
    projectV2: projectV2Data.projectV2BySlug ?? undefined,
  }
}
